// grid
$grid-columns: 12;
$grid-gutter-width-base: 30px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1550px
);
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base,
  xxl: $grid-gutter-width-base
);

@include assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include assert-starts-at-zero($grid-breakpoints);


// left and right padding's for .wrapper block
$wrapper-gutters: (
  xs: 15px,
  sm: 15px,
  md: 30px,
  lg: 30px,
  xl: 30px,
  xxl: 30px
);
$wrapper-max-width: 2000px;

$content-padding-top: 30px;
$content-padding-bottom: 50px;


// site color theme
$palette: (
  white: #ffffff,
  primary: #e41366,
  secondary: #b02784,
  link: #cccccc,
  link-hover: #ffffff,
  danger: #e41366,
  success: #28a745,
  warning: #ffc107,
  light-gray: rgba(#fff, 0.7),
  gray: rgba(#fff, 0.5),
  dark-gray: #909090,
  black: #000000
);
$link-colors: (
  white
);
$text-colors: (
  light-gray
);
$bg-colors: (
);
$btn-colors: (
  primary: ( text: color(white) )
);
$control-colors: (
  default: ( text: color(black), border: color(white), background: color(white) )
);


// selection
$selection-colors: (
  bg: rgba(color(primary), 0.35),
  img: rgba(color(primary), 0.15),
  text: color(black)
);
$selection-invert-colors: (
  bg: rgba(color(secondary), 0.85),
  img: rgba(color(secondary), 0.15),
  text: color(white)
);


// text styles
$font-family-sans-serif: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif;
$font-family-serif: "Georgia", "Times New Roman", "Times", serif;
$font-family-monospace: "Menlo", "Monaco", "Consolas", "Courier New", monospace;

$font-family-base: "Montserrat Alternates", $font-family-sans-serif;
$font-family-headings: "Montserrat Alternates", $font-family-sans-serif;

$font-weights: (
  thin: 100,
  extra-light: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semi-bold: 600,
  bold: 700,
  extra-bold: 800,
  black: 900
);
$font-weight-base: map_get($font-weights, regular);
$font-weight-strong: map_get($font-weights, semi-bold);

$font-size-base: 18px;
$font-size-base-small: 16px;
$font-size-lead: 1.125em;
$font-size-small: 16px;
$line-height-base: 2;
$line-height-small: 1.75;
$line-height-headings: 1.15;


// margins
$margins: (
  xs: 0.5rem,
  sm: 1rem,
  md: (30px/$font-size-base)+rem, // like grid gutter's
  lg: (50px/$font-size-base)+rem,
  xl: (150px/$font-size-base)+rem
);


// table styles
$table-styles: (
  heading-weight: map_get($font-weights, medium),
  thead-border-color: color(gray),
  border-color: color(light-gray),
  hover-color: rgba(color(primary), 0.05)
);


// forms
$form-group-margin: 20px;
$form-line-margin: 0.25rem;
$btn-outline-border-width: 2px;
$btn-horizontal-padding-multiplier: 0.3;
$form-control-horizontal-padding-multiplier: 0.2;
$form-control-border-width: 2px;
$form-control-line-height: 1.25;
$form-control-sizes: (
  default: ( height: 50px, font-size: 1rem, field-radius: 0, button-radius: 0 )
);


// checkbox
$checkbox-border-radius: 3px;
$checkbox-radio-bg: color(light-gray);
$checkbox-radio-bg-active: color(primary);
$checkbox-radio-icon-color: color(white);


// links group
$links-group-vertical-margin: map_get($margins, xs);
$links-group-horizontal-padding-multiplier: 0.3;


// cards
$cards: (
  default: ( max-width: none, padding: 0, border-radius: 0, background-color: none )
);


// google map sizes
$google-map-heights: (
  default: ( desktop: 300px, tablet: 250px, mobile: 150px ),
  large: ( desktop: 600px, tablet: 450px, mobile: 300px )
);


// modals
$modal-sizes: (
  default: ( max-width: 580px, padding: 35px 100px 60px 40px, border-radius: 0 )
);
$modal-bg-color: color(black);
$modal-text-color: color(white);
$modal-overlay-bg: rgba(#d1d1d1, 0.8);
