html {
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;

  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: color(white);

  @include media-breakpoint-down(sm) {
    font-size: $font-size-base-small;
    line-height: $line-height-small;
  }
}

b,
strong {
  font-weight: $font-weight-strong;
}

@each $weight in map-keys($font-weights) {
  .text-#{$weight} {
    font-weight: map_get($font-weights, $weight);
  }
}

small,
.text-small {
  font-size: $font-size-small;
  line-height: $line-height-small;
}

.text-lead {
  font-size: $font-size-lead;
}

.text-closely {
  line-height: $line-height-small;
}

.text-hyphenate {
  hyphens: auto;
  word-wrap: break-word;
}

p {
  margin: 1em 0 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin: 1em 0 0;
  font-family: $font-family-headings;
  line-height: $line-height-headings;
  color: color(white);
}

h1,
.h1 {
  margin: 0 0 2rem;
  font-weight: map_get($font-weights, light);
  font-size: (64px / 18px) + rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
    font-size: (40px / 16px) + rem;
  }
}

h2,
.h2 {
  margin: 0 0 2rem;
  font-weight: map_get($font-weights, light);
  font-size: (56px / 18px) + rem;

  @include media-breakpoint-down(sm) {
    margin: 0 0 1rem;
    font-size: (32px / 16px) + rem;
  }
}

h3,
.h3 {
  font-weight: map_get($font-weights, light);
  font-size: (40px / 18px) + rem;

  @include media-breakpoint-down(sm) {
    font-size: (24px / 16px) + rem;
  }
}

h4,
.h4 {
  font-weight: map_get($font-weights, light);
  font-size: (32px / 18px) + rem;
}

h5,
.h5 {
  font-weight: map_get($font-weights, light);
  font-size: (24px / 18px) + rem;

  @include media-breakpoint-down(sm) {
    font-size: (18px / 16px) + rem;
  }
}

ol:not([class]),
ul:not([class]) {
  padding: 0;
  margin: 0.5em 0 0.75em;

  ol:not([class]),
  ul:not([class]) {
    margin: 0.25em 0 0.5em 0;
  }

  li {
    position: relative;
    margin: 0.25rem 0 0 1.25rem;
  }
}

ul:not([class]):not([type]) {
  list-style: none;

  li::before {
    padding-right: 0.3em;
    position: absolute;
    right: 100%;
    content: "\2014";
  }

  li li::before {
    content: "\2022";
    text-align: center;
  }
}

ol:not([class]):not([type]):not([start]) {
  list-style-type: none;
  counter-reset: num;

  li {
    margin-left: 3ch;
  }

  li::before {
    position: absolute;
    box-sizing: content-box;
    padding-right: 0.3em;
    right: 100%;
    content: counter(num) ".";
    counter-increment: num;
  }

  ol {
    counter-reset: num-2lvl;
  }

  ol li {
    margin-left: 4ch;
  }

  ol li::before {
    content: counter(num) "." counter(num-2lvl);
    counter-increment: num-2lvl;
  }
}

p + ol:not([class]),
p + ul:not([class]) {
  margin-top: -0.25em;
}

blockquote {
  margin: 0.5em 0;
  border-left: 8px solid color(primary);
  padding: 0.75em 0 0.75em 1.25em;

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  cite {
    @extend .text-small;
    color: color(gray);
  }

  cite::before {
    margin-right: 0.3em;
    content: "\2014";
  }
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(color(dark-gray), 0.4);
}