@charset "UTF-8";
/**********
 * Mixins *
***********/
/***********************
* Настройки опасности задаются в этом документе
* Кроме этого должен быть создан селекторы "a" и "a u" со свойствами по-умолчанию
* (border-bottom-width, border-bottom-style, transition-ы и т.д.),
* а также класс .dashed для пунктирного подчеркивания
***********************/
/*************
 * Functions *
**************/
/*************
 * Variables *
**************/
/**************
 * Components *
***************/
.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

@media (min-width: 576px) { .row { margin-right: -15px; margin-left: -15px; } }

@media (min-width: 768px) { .row { margin-right: -15px; margin-left: -15px; } }

@media (min-width: 992px) { .row { margin-right: -15px; margin-left: -15px; } }

@media (min-width: 1200px) { .row { margin-right: -15px; margin-left: -15px; } }

@media (min-width: 1550px) { .row { margin-right: -15px; margin-left: -15px; } }

.no-gutters { margin-right: 0; margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl { box-sizing: border-box; position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; }

@media (min-width: 576px) { .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl { padding-right: 15px; padding-left: 15px; } }

@media (min-width: 768px) { .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl { padding-right: 15px; padding-left: 15px; } }

@media (min-width: 992px) { .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl { padding-right: 15px; padding-left: 15px; } }

@media (min-width: 1200px) { .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl { padding-right: 15px; padding-left: 15px; } }

@media (min-width: 1550px) { .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl { padding-right: 15px; padding-left: 15px; } }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }

.col-auto { flex: 0 0 auto; width: auto; }

.col-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }

.col-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }

.col-3 { flex: 0 0 25%; max-width: 25%; }

.col-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }

.col-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }

.col-6 { flex: 0 0 50%; max-width: 50%; }

.col-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }

.col-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }

.col-9 { flex: 0 0 75%; max-width: 75%; }

.col-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }

.col-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }

.col-12 { flex: 0 0 100%; max-width: 100%; }

.pull-0 { right: auto; }

.pull-1 { right: 8.33333%; }

.pull-2 { right: 16.66667%; }

.pull-3 { right: 25%; }

.pull-4 { right: 33.33333%; }

.pull-5 { right: 41.66667%; }

.pull-6 { right: 50%; }

.pull-7 { right: 58.33333%; }

.pull-8 { right: 66.66667%; }

.pull-9 { right: 75%; }

.pull-10 { right: 83.33333%; }

.pull-11 { right: 91.66667%; }

.pull-12 { right: 100%; }

.push-0 { left: auto; }

.push-1 { left: 8.33333%; }

.push-2 { left: 16.66667%; }

.push-3 { left: 25%; }

.push-4 { left: 33.33333%; }

.push-5 { left: 41.66667%; }

.push-6 { left: 50%; }

.push-7 { left: 58.33333%; }

.push-8 { left: 66.66667%; }

.push-9 { left: 75%; }

.push-10 { left: 83.33333%; }

.push-11 { left: 91.66667%; }

.push-12 { left: 100%; }

.offset-1 { margin-left: 8.33333%; }

.offset-2 { margin-left: 16.66667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.33333%; }

.offset-5 { margin-left: 41.66667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.33333%; }

.offset-8 { margin-left: 66.66667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.33333%; }

.offset-11 { margin-left: 91.66667%; }

@media (min-width: 576px) { .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; }
  .col-sm-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-sm-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-sm-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-sm-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-sm-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .pull-sm-0 { right: auto; }
  .pull-sm-1 { right: 8.33333%; }
  .pull-sm-2 { right: 16.66667%; }
  .pull-sm-3 { right: 25%; }
  .pull-sm-4 { right: 33.33333%; }
  .pull-sm-5 { right: 41.66667%; }
  .pull-sm-6 { right: 50%; }
  .pull-sm-7 { right: 58.33333%; }
  .pull-sm-8 { right: 66.66667%; }
  .pull-sm-9 { right: 75%; }
  .pull-sm-10 { right: 83.33333%; }
  .pull-sm-11 { right: 91.66667%; }
  .pull-sm-12 { right: 100%; }
  .push-sm-0 { left: auto; }
  .push-sm-1 { left: 8.33333%; }
  .push-sm-2 { left: 16.66667%; }
  .push-sm-3 { left: 25%; }
  .push-sm-4 { left: 33.33333%; }
  .push-sm-5 { left: 41.66667%; }
  .push-sm-6 { left: 50%; }
  .push-sm-7 { left: 58.33333%; }
  .push-sm-8 { left: 66.66667%; }
  .push-sm-9 { left: 75%; }
  .push-sm-10 { left: 83.33333%; }
  .push-sm-11 { left: 91.66667%; }
  .push-sm-12 { left: 100%; }
  .offset-sm-0 { margin-left: 0%; }
  .offset-sm-1 { margin-left: 8.33333%; }
  .offset-sm-2 { margin-left: 16.66667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.33333%; }
  .offset-sm-5 { margin-left: 41.66667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.33333%; }
  .offset-sm-8 { margin-left: 66.66667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.33333%; }
  .offset-sm-11 { margin-left: 91.66667%; } }

@media (min-width: 768px) { .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-md-auto { flex: 0 0 auto; width: auto; }
  .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-md-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-md-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-md-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-md-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .pull-md-0 { right: auto; }
  .pull-md-1 { right: 8.33333%; }
  .pull-md-2 { right: 16.66667%; }
  .pull-md-3 { right: 25%; }
  .pull-md-4 { right: 33.33333%; }
  .pull-md-5 { right: 41.66667%; }
  .pull-md-6 { right: 50%; }
  .pull-md-7 { right: 58.33333%; }
  .pull-md-8 { right: 66.66667%; }
  .pull-md-9 { right: 75%; }
  .pull-md-10 { right: 83.33333%; }
  .pull-md-11 { right: 91.66667%; }
  .pull-md-12 { right: 100%; }
  .push-md-0 { left: auto; }
  .push-md-1 { left: 8.33333%; }
  .push-md-2 { left: 16.66667%; }
  .push-md-3 { left: 25%; }
  .push-md-4 { left: 33.33333%; }
  .push-md-5 { left: 41.66667%; }
  .push-md-6 { left: 50%; }
  .push-md-7 { left: 58.33333%; }
  .push-md-8 { left: 66.66667%; }
  .push-md-9 { left: 75%; }
  .push-md-10 { left: 83.33333%; }
  .push-md-11 { left: 91.66667%; }
  .push-md-12 { left: 100%; }
  .offset-md-0 { margin-left: 0%; }
  .offset-md-1 { margin-left: 8.33333%; }
  .offset-md-2 { margin-left: 16.66667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.33333%; }
  .offset-md-5 { margin-left: 41.66667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.33333%; }
  .offset-md-8 { margin-left: 66.66667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.33333%; }
  .offset-md-11 { margin-left: 91.66667%; } }

@media (min-width: 992px) { .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; }
  .col-lg-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-lg-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-lg-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-lg-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-lg-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .pull-lg-0 { right: auto; }
  .pull-lg-1 { right: 8.33333%; }
  .pull-lg-2 { right: 16.66667%; }
  .pull-lg-3 { right: 25%; }
  .pull-lg-4 { right: 33.33333%; }
  .pull-lg-5 { right: 41.66667%; }
  .pull-lg-6 { right: 50%; }
  .pull-lg-7 { right: 58.33333%; }
  .pull-lg-8 { right: 66.66667%; }
  .pull-lg-9 { right: 75%; }
  .pull-lg-10 { right: 83.33333%; }
  .pull-lg-11 { right: 91.66667%; }
  .pull-lg-12 { right: 100%; }
  .push-lg-0 { left: auto; }
  .push-lg-1 { left: 8.33333%; }
  .push-lg-2 { left: 16.66667%; }
  .push-lg-3 { left: 25%; }
  .push-lg-4 { left: 33.33333%; }
  .push-lg-5 { left: 41.66667%; }
  .push-lg-6 { left: 50%; }
  .push-lg-7 { left: 58.33333%; }
  .push-lg-8 { left: 66.66667%; }
  .push-lg-9 { left: 75%; }
  .push-lg-10 { left: 83.33333%; }
  .push-lg-11 { left: 91.66667%; }
  .push-lg-12 { left: 100%; }
  .offset-lg-0 { margin-left: 0%; }
  .offset-lg-1 { margin-left: 8.33333%; }
  .offset-lg-2 { margin-left: 16.66667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.33333%; }
  .offset-lg-5 { margin-left: 41.66667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.33333%; }
  .offset-lg-8 { margin-left: 66.66667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.33333%; }
  .offset-lg-11 { margin-left: 91.66667%; } }

@media (min-width: 1200px) { .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; }
  .col-xl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-xl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-xl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-xl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .pull-xl-0 { right: auto; }
  .pull-xl-1 { right: 8.33333%; }
  .pull-xl-2 { right: 16.66667%; }
  .pull-xl-3 { right: 25%; }
  .pull-xl-4 { right: 33.33333%; }
  .pull-xl-5 { right: 41.66667%; }
  .pull-xl-6 { right: 50%; }
  .pull-xl-7 { right: 58.33333%; }
  .pull-xl-8 { right: 66.66667%; }
  .pull-xl-9 { right: 75%; }
  .pull-xl-10 { right: 83.33333%; }
  .pull-xl-11 { right: 91.66667%; }
  .pull-xl-12 { right: 100%; }
  .push-xl-0 { left: auto; }
  .push-xl-1 { left: 8.33333%; }
  .push-xl-2 { left: 16.66667%; }
  .push-xl-3 { left: 25%; }
  .push-xl-4 { left: 33.33333%; }
  .push-xl-5 { left: 41.66667%; }
  .push-xl-6 { left: 50%; }
  .push-xl-7 { left: 58.33333%; }
  .push-xl-8 { left: 66.66667%; }
  .push-xl-9 { left: 75%; }
  .push-xl-10 { left: 83.33333%; }
  .push-xl-11 { left: 91.66667%; }
  .push-xl-12 { left: 100%; }
  .offset-xl-0 { margin-left: 0%; }
  .offset-xl-1 { margin-left: 8.33333%; }
  .offset-xl-2 { margin-left: 16.66667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.33333%; }
  .offset-xl-5 { margin-left: 41.66667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.33333%; }
  .offset-xl-8 { margin-left: 66.66667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.33333%; }
  .offset-xl-11 { margin-left: 91.66667%; } }

@media (min-width: 1550px) { .col-xxl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-xxl-auto { flex: 0 0 auto; width: auto; }
  .col-xxl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xxl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xxl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xxl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-xxl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-xxl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xxl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-xxl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-xxl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xxl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-xxl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-xxl-12 { flex: 0 0 100%; max-width: 100%; }
  .pull-xxl-0 { right: auto; }
  .pull-xxl-1 { right: 8.33333%; }
  .pull-xxl-2 { right: 16.66667%; }
  .pull-xxl-3 { right: 25%; }
  .pull-xxl-4 { right: 33.33333%; }
  .pull-xxl-5 { right: 41.66667%; }
  .pull-xxl-6 { right: 50%; }
  .pull-xxl-7 { right: 58.33333%; }
  .pull-xxl-8 { right: 66.66667%; }
  .pull-xxl-9 { right: 75%; }
  .pull-xxl-10 { right: 83.33333%; }
  .pull-xxl-11 { right: 91.66667%; }
  .pull-xxl-12 { right: 100%; }
  .push-xxl-0 { left: auto; }
  .push-xxl-1 { left: 8.33333%; }
  .push-xxl-2 { left: 16.66667%; }
  .push-xxl-3 { left: 25%; }
  .push-xxl-4 { left: 33.33333%; }
  .push-xxl-5 { left: 41.66667%; }
  .push-xxl-6 { left: 50%; }
  .push-xxl-7 { left: 58.33333%; }
  .push-xxl-8 { left: 66.66667%; }
  .push-xxl-9 { left: 75%; }
  .push-xxl-10 { left: 83.33333%; }
  .push-xxl-11 { left: 91.66667%; }
  .push-xxl-12 { left: 100%; }
  .offset-xxl-0 { margin-left: 0%; }
  .offset-xxl-1 { margin-left: 8.33333%; }
  .offset-xxl-2 { margin-left: 16.66667%; }
  .offset-xxl-3 { margin-left: 25%; }
  .offset-xxl-4 { margin-left: 33.33333%; }
  .offset-xxl-5 { margin-left: 41.66667%; }
  .offset-xxl-6 { margin-left: 50%; }
  .offset-xxl-7 { margin-left: 58.33333%; }
  .offset-xxl-8 { margin-left: 66.66667%; }
  .offset-xxl-9 { margin-left: 75%; }
  .offset-xxl-10 { margin-left: 83.33333%; }
  .offset-xxl-11 { margin-left: 91.66667%; } }

.flex-first { order: -1; }

.flex-last { order: 1; }

.flex-unordered { order: 0; }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-first { order: -1; }
  .flex-sm-last { order: 1; }
  .flex-sm-unordered { order: 0; }
  .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }

@media (min-width: 768px) { .flex-md-first { order: -1; }
  .flex-md-last { order: 1; }
  .flex-md-unordered { order: 0; }
  .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }

@media (min-width: 992px) { .flex-lg-first { order: -1; }
  .flex-lg-last { order: 1; }
  .flex-lg-unordered { order: 0; }
  .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }

@media (min-width: 1200px) { .flex-xl-first { order: -1; }
  .flex-xl-last { order: 1; }
  .flex-xl-unordered { order: 0; }
  .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }

@media (min-width: 1550px) { .flex-xxl-first { order: -1; }
  .flex-xxl-last { order: 1; }
  .flex-xxl-unordered { order: 0; }
  .flex-xxl-row { flex-direction: row !important; }
  .flex-xxl-column { flex-direction: column !important; }
  .flex-xxl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xxl-wrap { flex-wrap: wrap !important; }
  .flex-xxl-nowrap { flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start { justify-content: flex-start !important; }
  .justify-content-xxl-end { justify-content: flex-end !important; }
  .justify-content-xxl-center { justify-content: center !important; }
  .justify-content-xxl-between { justify-content: space-between !important; }
  .justify-content-xxl-around { justify-content: space-around !important; }
  .align-items-xxl-start { align-items: flex-start !important; }
  .align-items-xxl-end { align-items: flex-end !important; }
  .align-items-xxl-center { align-items: center !important; }
  .align-items-xxl-baseline { align-items: baseline !important; }
  .align-items-xxl-stretch { align-items: stretch !important; }
  .align-content-xxl-start { align-content: flex-start !important; }
  .align-content-xxl-end { align-content: flex-end !important; }
  .align-content-xxl-center { align-content: center !important; }
  .align-content-xxl-between { align-content: space-between !important; }
  .align-content-xxl-around { align-content: space-around !important; }
  .align-content-xxl-stretch { align-content: stretch !important; }
  .align-self-xxl-auto { align-self: auto !important; }
  .align-self-xxl-start { align-self: flex-start !important; }
  .align-self-xxl-end { align-self: flex-end !important; }
  .align-self-xxl-center { align-self: center !important; }
  .align-self-xxl-baseline { align-self: baseline !important; }
  .align-self-xxl-stretch { align-self: stretch !important; } }

.invisible { visibility: hidden; }

.hidden-xs-up { display: none !important; }

@media (max-width: 575px) { .hidden-xs-down { display: none !important; } }

@media (min-width: 576px) { .hidden-sm-up { display: none !important; } }

@media (max-width: 767px) { .hidden-sm-down { display: none !important; } }

@media (min-width: 768px) { .hidden-md-up { display: none !important; } }

@media (max-width: 991px) { .hidden-md-down { display: none !important; } }

@media (min-width: 992px) { .hidden-lg-up { display: none !important; } }

@media (max-width: 1199px) { .hidden-lg-down { display: none !important; } }

@media (min-width: 1200px) { .hidden-xl-up { display: none !important; } }

@media (max-width: 1549px) { .hidden-xl-down { display: none !important; } }

@media (min-width: 1550px) { .hidden-xxl-up { display: none !important; } }

.hidden-xxl-down { display: none !important; }

.visible-print-block { display: none !important; }

@media print { .visible-print-block { display: block !important; } }

.visible-print-inline { display: none !important; }

@media print { .visible-print-inline { display: inline !important; } }

.visible-print-inline-block { display: none !important; }

@media print { .visible-print-inline-block { display: inline-block !important; } }

@media print { .hidden-print { display: none !important; } }

html { -ms-overflow-style: scrollbar; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); background-color: #000000; }

*, *::before, *::after { box-sizing: inherit; }

@-ms-viewport { width: device-width; }

body { margin: 0; }

.layout { display: flex; flex-direction: column; min-height: 100vh; }

.main { flex: 1 0 auto; max-width: 100%; }

.text-block { max-width: 50rem; }

.text-block > *:first-child { margin-top: 0; }

.wrapper { max-width: 2000px; margin: 0 auto; padding: 0 15px; }

@media (min-width: 576px) { .wrapper { padding: 0 15px; } }

@media (min-width: 768px) { .wrapper { padding: 0 30px; } }

@media (min-width: 992px) { .wrapper { padding: 0 30px; } }

@media (min-width: 1200px) { .wrapper { padding: 0 30px; } }

@media (min-width: 1550px) { .wrapper { padding: 0 30px; } }

.wrapper-half { box-sizing: content-box; max-width: 985px; padding-right: 15px; }

@media (min-width: 576px) { .wrapper-half { padding-right: 15px; } }

@media (min-width: 768px) { .wrapper-half { padding-right: 30px; } }

@media (min-width: 992px) { .wrapper-half { padding-right: 30px; } }

@media (min-width: 1200px) { .wrapper-half { padding-right: 30px; } }

@media (min-width: 1550px) { .wrapper-half { padding-right: 30px; } }

@media (max-width: 991px) { .wrapper-half { max-width: none; } }

.unwrapper { padding: -15px; }

@media (min-width: 576px) { .unwrapper { padding: -15px; } }

@media (min-width: 768px) { .unwrapper { padding: -30px; } }

@media (min-width: 992px) { .unwrapper { padding: -30px; } }

@media (min-width: 1200px) { .unwrapper { padding: -30px; } }

@media (min-width: 1550px) { .unwrapper { padding: -30px; } }

img { max-width: 100%; height: auto; }

img:only-child { vertical-align: bottom; }

svg { fill: currentColor; }

.lazy { display: block; opacity: 0; transition: opacity 0.3s; }

.lazy.loaded { opacity: 1; }

.swiper-lazy { opacity: 0; transition: opacity 0.3s; }

.swiper-lazy.swiper-lazy-loaded { opacity: 1; }

.img-circle { border-radius: 50%; }

@media (min-width: 768px) and (max-width: 991px) { .img-smart { max-width: calc(100% + 60px); margin: 0 0 0 -30px; } }

@media (min-width: 576px) and (max-width: 767px) { .img-smart { max-width: calc(100% + 30px); margin: 0 0 0 -15px; } }

@media (max-width: 575px) { .img-smart { max-width: calc(100% + 30px); margin: 0 0 0 -15px; } }

@font-face { font-display: swap; font-family: "Montserrat Alternates"; font-style: normal; font-weight: 400; src: url("../fonts/montserrat-alternates/montserrat-alternates-v10-cyrillic_latin-regular.woff2") format("woff2"), url("../fonts/montserrat-alternates/montserrat-alternates-v10-cyrillic_latin-regular.woff") format("woff"); }

@font-face { font-display: swap; font-family: "Montserrat Alternates"; font-style: normal; font-weight: 300; src: url("../fonts/montserrat-alternates/montserrat-alternates-v10-cyrillic_latin-300.woff2") format("woff2"), url("../fonts/montserrat-alternates/montserrat-alternates-v10-cyrillic_latin-300.woff") format("woff"); }

html { -webkit-text-size-adjust: 100%; font-variant-ligatures: none; text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; font-family: "Montserrat Alternates", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif; font-size: 18px; line-height: 2; color: #ffffff; }

@media (max-width: 767px) { html { font-size: 16px; line-height: 1.75; } }

b, strong { font-weight: 600; }

.text-thin { font-weight: 100; }

.text-extra-light { font-weight: 200; }

.text-light { font-weight: 300; }

.text-regular { font-weight: 400; }

.text-medium { font-weight: 500; }

.text-semi-bold { font-weight: 600; }

.text-bold { font-weight: 700; }

.text-extra-bold { font-weight: 800; }

.text-black { font-weight: 900; }

small, .text-small, blockquote cite, .form-error { font-size: 16px; line-height: 1.75; }

.text-lead { font-size: 1.125em; }

.text-closely { line-height: 1.75; }

.text-hyphenate { hyphens: auto; word-wrap: break-word; }

p { margin: 1em 0 0; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 { margin: 1em 0 0; font-family: "Montserrat Alternates", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen-Sans", "Ubuntu", "Cantarell", "Helvetica Neue", sans-serif; line-height: 1.15; color: #ffffff; }

h1, .h1 { margin: 0 0 2rem; font-weight: 300; font-size: 3.55556rem; }

@media (max-width: 767px) { h1, .h1 { margin-bottom: 1rem; font-size: 2.5rem; } }

h2, .h2 { margin: 0 0 2rem; font-weight: 300; font-size: 3.11111rem; }

@media (max-width: 767px) { h2, .h2 { margin: 0 0 1rem; font-size: 2rem; } }

h3, .h3 { font-weight: 300; font-size: 2.22222rem; }

@media (max-width: 767px) { h3, .h3 { font-size: 1.5rem; } }

h4, .h4 { font-weight: 300; font-size: 1.77778rem; }

h5, .h5 { font-weight: 300; font-size: 1.33333rem; }

@media (max-width: 767px) { h5, .h5 { font-size: 1.125rem; } }

ol:not([class]), ul:not([class]) { padding: 0; margin: 0.5em 0 0.75em; }

ol:not([class]) ol:not([class]), ol:not([class]) ul:not([class]), ul:not([class]) ol:not([class]), ul:not([class]) ul:not([class]) { margin: 0.25em 0 0.5em 0; }

ol:not([class]) li, ul:not([class]) li { position: relative; margin: 0.25rem 0 0 1.25rem; }

ul:not([class]):not([type]) { list-style: none; }

ul:not([class]):not([type]) li::before { padding-right: 0.3em; position: absolute; right: 100%; content: "\2014"; }

ul:not([class]):not([type]) li li::before { content: "\2022"; text-align: center; }

ol:not([class]):not([type]):not([start]) { list-style-type: none; counter-reset: num; }

ol:not([class]):not([type]):not([start]) li { margin-left: 3ch; }

ol:not([class]):not([type]):not([start]) li::before { position: absolute; box-sizing: content-box; padding-right: 0.3em; right: 100%; content: counter(num) "."; counter-increment: num; }

ol:not([class]):not([type]):not([start]) ol { counter-reset: num-2lvl; }

ol:not([class]):not([type]):not([start]) ol li { margin-left: 4ch; }

ol:not([class]):not([type]):not([start]) ol li::before { content: counter(num) "." counter(num-2lvl); counter-increment: num-2lvl; }

p + ol:not([class]), p + ul:not([class]) { margin-top: -0.25em; }

blockquote { margin: 0.5em 0; border-left: 8px solid #e41366; padding: 0.75em 0 0.75em 1.25em; }

blockquote > *:first-child { margin-top: 0; }

blockquote > *:last-child { margin-bottom: 0; }

blockquote cite { color: rgba(255, 255, 255, 0.5); }

blockquote cite::before { margin-right: 0.3em; content: "\2014"; }

hr { margin-top: 2rem; margin-bottom: 2rem; border: 0; border-top: 1px solid rgba(144, 144, 144, 0.4); }

.text-nowrap { white-space: nowrap; }

.text-truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }

@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }

@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }

@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }

@media (min-width: 1550px) { .text-xxl-left { text-align: left !important; }
  .text-xxl-right { text-align: right !important; }
  .text-xxl-center { text-align: center !important; } }

.text-lowercase { text-transform: lowercase; }

.text-uppercase { text-transform: uppercase; }

.text-capitalize { text-transform: capitalize; }

.table-responsive, table:not([class]) { margin: 0.5rem 0 0.75rem; border-spacing: 0; border-collapse: collapse; }

.table-responsive td:not([valign]), .table-responsive th:not([valign]), table:not([class]) td:not([valign]), table:not([class]) th:not([valign]) { vertical-align: top; }

.table-responsive th:not([align]), table:not([class]) th:not([align]) { text-align: left; }

.table-responsive th, table:not([class]) th { padding: 0.5rem 1.5rem; font-weight: 500; border-bottom: 1px solid; }

.table-responsive tbody td, table:not([class]) tbody td { padding: 1rem 1.5rem; border-bottom: 1px solid rgba(255, 255, 255, 0.7); transition: background-color 0.3s; }

@media (min-width: 992px) { .table-responsive tbody tr:hover td, table:not([class]) tbody tr:hover td { background-color: rgba(228, 19, 102, 0.05); transition-duration: 0.1s; } }

table:not([class]) { display: inline-block; overflow-x: auto; max-width: 100%; vertical-align: top; background: linear-gradient(rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%) 0 0, linear-gradient(rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%) 100% 0; background-attachment: scroll, scroll; background-size: 1px 100%, 1px 100%; background-repeat: no-repeat, no-repeat; }

table:not([class]) tbody tr:last-child td { border-bottom: none; }

table:not([class]) td:first-child, table:not([class]) th:first-child { background-image: linear-gradient(to right, white 50%, rgba(255, 255, 255, 0) 100%); background-size: 2px 100%; background-repeat: no-repeat; }

table:not([class]) td:last-child, table:not([class]) th:last-child { background-image: linear-gradient(to left, white 50%, rgba(255, 255, 255, 0) 100%); background-position: 100% 0; background-size: 2px 100%; background-repeat: no-repeat; }

table:not([class]) td:only-child, table:not([class]) th:only-child { background-image: linear-gradient(to right, white 50%, rgba(255, 255, 255, 0) 100%), linear-gradient(to left, white 50%, rgba(255, 255, 255, 0) 100%); background-position: 0 0, 100% 0; background-size: 2px 100%, 2px 100%; background-repeat: no-repeat, no-repeat; }

@media (max-width: 991px) { .table-responsive { display: block; } }

@media (max-width: 991px) { .table-responsive tr, .table-responsive tbody { display: block; } }

@media (max-width: 991px) { .table-responsive thead { display: none; } }

@media (max-width: 991px) { .table-responsive tr { margin-bottom: 1rem; } }

@media (max-width: 991px) { .table-responsive tbody td, .table-responsive thead th { display: flex; padding-left: 0; padding-right: 0; } }

@media (max-width: 575px) { .table-responsive tbody td, .table-responsive thead th { display: block; } }

@media (max-width: 991px) { .table-responsive tbody td::before { content: attr(data-caption); flex: 0 0 30%; padding-right: 10px; font-weight: bold; } }

@media (max-width: 575px) { .table-responsive tbody td::before { display: block; flex: none; padding-right: 0; } }

@media (min-width: 992px) { .table-responsive tbody tr:last-child td { border-bottom: none; } }

@media (max-width: 991px) { .table-responsive tbody td:last-child { border-bottom: none; } }

.text-light-gray { color: rgba(255, 255, 255, 0.7); }

a, a u { text-decoration: none; border-color: inherit; border-bottom-width: 1px; border-bottom-style: solid; transition-duration: 0.3s; }

a { color: #cccccc; border-bottom-color: rgba(204, 204, 204, 0.3); background-color: transparent; -webkit-text-decoration-skip: objects; outline-color: inherit; transition-property: color, border-color; }

a u { border-bottom-color: rgba(204, 204, 204, 0.3); }

a:hover { color: #ffffff; border-bottom-color: rgba(255, 255, 255, 0.3); }

a:hover u { border-bottom-color: rgba(255, 255, 255, 0.3); }

a.dashed, a.dashed u { border-bottom-color: rgba(204, 204, 204, 0.5); }

a.dashed:hover, a.dashed:hover u { border-bottom-color: rgba(255, 255, 255, 0.5); }

a:active, a:hover { outline-width: 0; }

a u { transition-property: border-color; }

a[href^="tel"] { text-decoration: none; color: inherit; border: none; }

a:hover, a:hover u { transition-duration: 0.1s; }

.dashed, .dashed u { border-bottom-style: dashed; }

.no-border, .card__link, .google-map a { border-bottom: none; }

.link-white { color: #ffffff; border-bottom-color: rgba(255, 255, 255, 0.3); }

.link-white u { border-bottom-color: rgba(255, 255, 255, 0.3); }

.link-white:hover { color: #ffffff; border-bottom-color: white; }

.link-white:hover u { border-bottom-color: white; }

.link-white.dashed, .link-white.dashed u { border-bottom-color: rgba(255, 255, 255, 0.5); }

.link-white.dashed:hover, .link-white.dashed:hover u { border-bottom-color: white; }

.btn { appearance: none; max-width: 100%; display: inline-block; text-decoration: none; border: none; vertical-align: middle; cursor: pointer; transition-property: border-color, background-color, color; transition-duration: 0.3s; outline: none !important; font-family: inherit; font-weight: 400; padding: calc((50px - 1.11111rem * 1.25 - 4px) / 2) 15px; border: 2px solid transparent; border-radius: 0; font-size: 1.11111rem; line-height: 1.25; background-color: transparent; color: #000000; border-color: #000000; font-weight: 600; }

.btn:hover { transition-duration: 0.1s; }

.btn:disabled, .btn[disabled], fieldset[disabled] .btn, .btn.disabled { pointer-events: none; }

.btn--block { display: block; width: 100%; padding: 0; text-align: center; }

.btn:hover { background-color: transparent; color: #e41366; }

.btn:active, .btn:focus, .btn.active { background-color: transparent; color: #b02784; }

.btn:disabled, .btn[disabled], fieldset[disabled] .btn, .btn.disabled { background-color: rgba(89, 89, 89, 0); color: #595959; }

.btn:hover { border-color: #e41366; }

.btn:active, .btn:focus, .btn.active { border-color: #b02784; }

.btn:disabled, .btn[disabled], fieldset[disabled] .btn, .btn.disabled { border-color: #595959; }

.btn--white { background-color: transparent; color: #ffffff; border-color: #ffffff; }

.btn--white:hover { background-color: transparent; color: #e41366; }

.btn--white:active, .btn--white:focus, .btn--white.active { background-color: transparent; color: #b02784; }

.btn--white:disabled, .btn--white[disabled], fieldset[disabled] .btn--white, .btn--white.disabled { background-color: rgba(89, 89, 89, 0); color: white; }

.btn--white:hover { border-color: #e41366; }

.btn--white:active, .btn--white:focus, .btn--white.active { border-color: #b02784; }

.btn--white:disabled, .btn--white[disabled], fieldset[disabled] .btn--white, .btn--white.disabled { border-color: white; }

.circle-btn { appearance: none; max-width: 100%; display: inline-block; text-decoration: none; border: none; vertical-align: middle; cursor: pointer; transition-property: border-color, background-color, color; transition-duration: 0.3s; outline: none !important; font-family: inherit; font-weight: 400; display: flex; width: 60px; height: 60px; align-items: center; justify-content: center; padding: 0; outline: none; color: #000000; border-radius: 50%; background-color: #ffffff; }

.circle-btn:hover { transition-duration: 0.1s; }

.circle-btn:disabled, .circle-btn[disabled], fieldset[disabled] .circle-btn, .circle-btn.disabled { pointer-events: none; }

.circle-btn--block { display: block; width: 100%; padding: 0; text-align: center; }

.circle-btn:hover { color: #e41366; }

.circle-btn svg { width: 20px; height: 20px; }

.circle-btn--arrow svg { transform: translateX(2px); }

.form { max-width: 700px; }

.form-group { margin: 20px 0 0; }

.form-group__line { margin: 0.25rem 0 0; }

.form-group--horizontal { display: flex; align-items: baseline; }

@media (max-width: 575px) { .form-group--horizontal { display: block; } }

.form-group--horizontal .form-group__label { flex: 0 0 15ch; max-width: 15ch; margin-right: 5px; line-height: 1.25; }

@media (max-width: 575px) { .form-group--horizontal .form-group__label { display: block; flex: none; max-width: none; margin-right: 0; margin-bottom: 5px; } }

.form-group--horizontal .form-group__field { flex-grow: 1; max-width: calc(100% - 15ch - 5px); }

@media (max-width: 575px) { .form-group--horizontal .form-group__field { flex: none; max-width: none; } }

.form-control { appearance: none; display: block; max-width: 100%; background-image: none; background-clip: padding-box; line-height: 1.25; font-family: inherit; font-weight: 400; transition: border-color 0.3s, background-color 0.3s, color 0.3s; outline: none; border-width: 2px; border-style: solid; border-color: transparent; padding: calc((50px - 1rem * 1.25 - 2 * 2px) / 2) 10px; border-radius: 0; font-size: 1rem; color: #000000; background-color: #fff; border-color: #ffffff; }

.form-control:not([size]):not(select), .form-control--full-size { width: 100%; }

.form-control::-ms-expand { background-color: transparent; border: 0; }

.form-control:focus { transition-duration: 0.1s; }

textarea.form-control { resize: vertical; }

select.form-control:not([size]):not([multiple]) { background-position: center right 10px; background-repeat: no-repeat; }

select.form-control:not([size]):not([multiple]) { height: 50px; padding-right: calc(10px + 10px); }

.form-control::placeholder { color: rgba(0, 0, 0, 0.5); opacity: 1; }

select.form-control:not([size]):not([multiple]) { background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='5' fill='#000000'><polygon points='0,0 10,0 5,5'/></svg>"); }

select.form-control:focus::-ms-value { color: #000000; background-color: #fff; }

.form-control:focus { outline: none; color: #000000; background-color: #fff; }

.form-control:disabled, .form-control[readonly] { background-color: #e6e6e6; color: #000000; opacity: 1; }

.form-control:focus { border-color: #ffffff; }

.form-control:disabled, .form-control[readonly] { border-color: #ffffff; }

select.form-control:not([size]):not([multiple]) { background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='5' fill='#ffffff'><polygon points='0,0 10,0 5,5'/></svg>"); }

select.form-control:not([size]):not([multiple]):focus { background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='5' fill='#ffffff'><polygon points='0,0 10,0 5,5'/></svg>"); }

.form-control.error { border-color: #e41366; }

.form-error { margin-top: 0.25rem; color: #e41366; }

.checkbox { position: relative; display: block; font-size: 1rem; line-height: 1.25; min-height: 1em; padding-left: 1.5em; }

.checkbox__label::before, .checkbox__label::after { position: absolute; top: 0.125em; left: 0; width: 1em; height: 1em; content: ""; }

.checkbox__label::before { pointer-events: none; user-select: none; background-color: rgba(255, 255, 255, 0.7); border-radius: 3px; transition: background-color 0.3s; }

.checkbox__label::after { position: absolute; background-repeat: no-repeat; background-position: center center; background-size: 0.7em auto; }

.checkbox__input { position: absolute; z-index: -1; opacity: 0; }

.checkbox__input:checked ~ .checkbox__label::before { background-color: #e41366; }

.checkbox__input:checked ~ .checkbox__label::after { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.checkbox__input:not(:disabled):not(:checked) ~ .checkbox__label:hover::before { background-color: rgba(228, 19, 102, 0.5); transition-duration: 0.1s; }

.checkbox__input:disabled ~ .checkbox__label { color: rgba(255, 255, 255, 0.7); }

.checkbox__input:disabled ~ .checkbox__label::before, .checkbox__input:disabled ~ .checkbox__label::after { opacity: 0.5; }

.checkbox__input.error ~ .checkbox__label::before { background-color: rgba(228, 19, 102, 0.25); }

.radio { position: relative; display: block; font-size: 1rem; line-height: 1.25; min-height: 1em; padding-left: 1.5em; }

.radio__label::before, .radio__label::after { position: absolute; top: 0.125em; left: 0; width: 1em; height: 1em; content: ""; }

.radio__label::before { pointer-events: none; user-select: none; background-color: rgba(255, 255, 255, 0.7); border-radius: 50%; transition: background-color 0.3s; }

.radio__label::after { position: absolute; background-repeat: no-repeat; background-position: center center; background-size: 0.7em auto; }

.radio__input { position: absolute; z-index: -1; opacity: 0; }

.radio__input:checked ~ .radio__label::before { background-color: #e41366; }

.radio__input:checked ~ .radio__label::after { background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#ffffff'/%3E%3C/svg%3E"); }

.radio__input:not(:disabled):not(:checked) ~ .radio__label:hover::before { background-color: rgba(228, 19, 102, 0.5); transition-duration: 0.1s; }

.radio__input:disabled ~ .radio__label { color: rgba(255, 255, 255, 0.7); }

.radio__input:disabled ~ .radio__label::before, .radio__input:disabled ~ .radio__label::after { opacity: 0.5; }

.links-group { display: flex; flex-wrap: wrap; align-items: baseline; margin-left: -15px; }

.links-group--center { justify-content: center; }

.links-group__item { max-width: 100%; border-bottom: none; line-height: 1.25; margin-top: 0.5rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.links-group__item--pagination { padding-left: 0; padding-right: 0; text-align: center; }

.links-group__item { padding: calc((50px - 1rem * 1.25) / 2) 15px; font-size: 1rem; border-radius: 0; }

.links-group__item--pagination { width: 50px; }

.links-group__item--active { background-color: #e41366; color: #ffffff; pointer-events: none; }

.links-group__item--active u { border-bottom: none; }

.links-group__item--disabled { color: rgba(255, 255, 255, 0.5); pointer-events: none; }

.links-group__item--disabled u { border-bottom: none; }

.card { max-width: 360px; font-size: 0.88889rem; }

@media (max-width: 767px) { .card { font-size: 1rem; } }

.card__image { display: block; }

.card__image img { vertical-align: bottom; }

.card__name, .card__price { font-weight: 600; font-size: 1.11111rem; }

@media (max-width: 767px) { .card__name, .card__price { font-size: 1.25rem; } }

.card__name { display: block; margin-top: 15px; }

.card__name b { font-weight: 900; text-transform: uppercase; letter-spacing: 0.2em; }

.card__price { margin-top: 5px; white-space: nowrap; color: #000000; }

.google-map { background-color: #dfdfdf; height: 300px; }

@media (max-width: 991px) { .google-map { height: 250px; } }

@media (max-width: 767px) { .google-map { height: 150px; } }

.google-map--large { height: 600px; }

@media (max-width: 991px) { .google-map--large { height: 450px; } }

@media (max-width: 767px) { .google-map--large { height: 300px; } }

@media (max-width: 767px) and (min-width: 576px) and (max-width: 767px) { .google-map { margin: -15px; } }

@media (max-width: 767px) and (max-width: 575px) { .google-map { margin: -15px; } }

.google-map__marker { display: none; }

.modal { z-index: 999; }

.modal__overlay { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(209, 209, 209, 0.8); display: flex; justify-content: center; align-items: center; z-index: 999; }

.modal__container { position: relative; background-color: #000000; color: #ffffff; overflow-y: auto; box-sizing: border-box; }

@media (max-width: 575px) { .modal__container { width: calc(100% - 30px); max-height: calc(100vh - 30px); } }

@media (min-width: 576px) and (max-width: 767px) { .modal__container { width: calc(100% - 30px); max-height: calc(100vh - 30px); } }

@media (min-width: 768px) and (max-width: 991px) { .modal__container { width: calc(100% - 60px); max-height: calc(100vh - 60px); } }

@media (min-width: 992px) and (max-width: 1199px) { .modal__container { width: calc(100% - 60px); max-height: calc(100vh - 60px); } }

@media (min-width: 1200px) and (max-width: 1549px) { .modal__container { width: calc(100% - 60px); max-height: calc(100vh - 60px); } }

@media (min-width: 1550px) { .modal__container { width: calc(100% - 60px); max-height: calc(100vh - 60px); } }

.modal--default .modal__container { max-width: 580px; padding: 35px 100px 60px 40px; border-radius: 0; }

@media (max-width: 767px) { .modal--default .modal__container { padding: 30px; } }

.modal--default .modal__container::after { position: absolute; left: 0; right: 0; transform: translateY(55px); height: 5px; background-color: #e41366; background-image: linear-gradient(to right, #b02784, #e41366); content: ""; }

@media (max-width: 767px) { .modal--default .modal__container::after { transform: translateY(25px); } }

.modal__header { display: flex; justify-content: space-between; align-items: baseline; }

.modal__title { margin: 0 15px 0 0; color: #ffffff; }

.modal__close { position: absolute; appearance: none; display: flex; align-items: center; justify-content: center; width: 44px; height: 44px; padding: 0; border: 3px solid #ffffff; outline: none; background-color: transparent; transition: border-color 0.3s, color 0.3s; color: #ffffff; cursor: pointer; right: 15px; top: 15px; z-index: 2; }

.modal__close svg { width: 20px; height: 20px; pointer-events: none; }

.modal__close:hover, .modal__close:focus { color: #e41366; border-color: #e41366; transition-duration: 0.1s; }

.modal__close:active { border-color: #b02784; color: #b02784; }

.modal__content { margin-top: 20px; margin-bottom: 2rem; }

@keyframes mmfadeIn { from { opacity: 0; }
  to { opacity: 1; } }

@keyframes mmfadeOut { from { opacity: 1; }
  to { opacity: 0; } }

@keyframes mmslideIn { from { transform: translateY(15%); }
  to { transform: translateY(0); } }

@keyframes mmslideOut { from { transform: translateY(0); }
  to { transform: translateY(-10%); } }

.micromodal-slide { display: none; }

.micromodal-slide.is-open { display: block; }

.micromodal-slide[aria-hidden="false"] .modal__overlay { animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="false"] .modal__container { animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__overlay { animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide[aria-hidden="true"] .modal__container { animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }

.micromodal-slide .modal__container, .micromodal-slide .modal__overlay { will-change: transform; }

@keyframes donut-spin { 0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } }

.preloader { display: inline-block; border: 4px solid #000000; border-left-color: #e41366; border-radius: 50%; width: 30px; height: 30px; animation: donut-spin 1.2s linear infinite; }

.mt-xs { margin-top: 0.5rem; }

@media (max-width: 767px) { .mt-xs-phone { margin-top: 0.5rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mt-xs-tablet { margin-top: 0.5rem; } }

@media (min-width: 992px) { .mt-xs-desktop { margin-top: 0.5rem; } }

.mb-xs { margin-bottom: 0.5rem; }

@media (max-width: 767px) { .mb-xs-phone { margin-bottom: 0.5rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mb-xs-tablet { margin-bottom: 0.5rem; } }

@media (min-width: 992px) { .mb-xs-desktop { margin-bottom: 0.5rem; } }

.ml-xs { margin-left: 0.5rem; }

@media (max-width: 767px) { .ml-xs-phone { margin-left: 0.5rem; } }

@media (min-width: 768px) and (max-width: 991px) { .ml-xs-tablet { margin-left: 0.5rem; } }

@media (min-width: 992px) { .ml-xs-desktop { margin-left: 0.5rem; } }

.mr-xs { margin-right: 0.5rem; }

@media (max-width: 767px) { .mr-xs-phone { margin-right: 0.5rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mr-xs-tablet { margin-right: 0.5rem; } }

@media (min-width: 992px) { .mr-xs-desktop { margin-right: 0.5rem; } }

.mx-xs { margin-left: 0.5rem; margin-right: 0.5rem; }

@media (max-width: 767px) { .mx-xs-phone { margin-left: 0.5rem; margin-right: 0.5rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mx-xs-tablet { margin-left: 0.5rem; margin-right: 0.5rem; } }

@media (min-width: 992px) { .mx-xs-desktop { margin-left: 0.5rem; margin-right: 0.5rem; } }

.my-xs { margin-top: 0.5rem; margin-bottom: 0.5rem; }

@media (max-width: 767px) { .my-xs-phone { margin-top: 0.5rem; margin-bottom: 0.5rem; } }

@media (min-width: 768px) and (max-width: 991px) { .my-xs-tablet { margin-top: 0.5rem; margin-bottom: 0.5rem; } }

@media (min-width: 992px) { .my-xs-desktop { margin-top: 0.5rem; margin-bottom: 0.5rem; } }

.mt-sm { margin-top: 1rem; }

@media (max-width: 767px) { .mt-sm-phone { margin-top: 1rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mt-sm-tablet { margin-top: 1rem; } }

@media (min-width: 992px) { .mt-sm-desktop { margin-top: 1rem; } }

.mb-sm { margin-bottom: 1rem; }

@media (max-width: 767px) { .mb-sm-phone { margin-bottom: 1rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mb-sm-tablet { margin-bottom: 1rem; } }

@media (min-width: 992px) { .mb-sm-desktop { margin-bottom: 1rem; } }

.ml-sm { margin-left: 1rem; }

@media (max-width: 767px) { .ml-sm-phone { margin-left: 1rem; } }

@media (min-width: 768px) and (max-width: 991px) { .ml-sm-tablet { margin-left: 1rem; } }

@media (min-width: 992px) { .ml-sm-desktop { margin-left: 1rem; } }

.mr-sm { margin-right: 1rem; }

@media (max-width: 767px) { .mr-sm-phone { margin-right: 1rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mr-sm-tablet { margin-right: 1rem; } }

@media (min-width: 992px) { .mr-sm-desktop { margin-right: 1rem; } }

.mx-sm { margin-left: 1rem; margin-right: 1rem; }

@media (max-width: 767px) { .mx-sm-phone { margin-left: 1rem; margin-right: 1rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mx-sm-tablet { margin-left: 1rem; margin-right: 1rem; } }

@media (min-width: 992px) { .mx-sm-desktop { margin-left: 1rem; margin-right: 1rem; } }

.my-sm { margin-top: 1rem; margin-bottom: 1rem; }

@media (max-width: 767px) { .my-sm-phone { margin-top: 1rem; margin-bottom: 1rem; } }

@media (min-width: 768px) and (max-width: 991px) { .my-sm-tablet { margin-top: 1rem; margin-bottom: 1rem; } }

@media (min-width: 992px) { .my-sm-desktop { margin-top: 1rem; margin-bottom: 1rem; } }

.mt-md { margin-top: 1.66667rem; }

@media (max-width: 767px) { .mt-md-phone { margin-top: 1.66667rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mt-md-tablet { margin-top: 1.66667rem; } }

@media (min-width: 992px) { .mt-md-desktop { margin-top: 1.66667rem; } }

.mb-md { margin-bottom: 1.66667rem; }

@media (max-width: 767px) { .mb-md-phone { margin-bottom: 1.66667rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mb-md-tablet { margin-bottom: 1.66667rem; } }

@media (min-width: 992px) { .mb-md-desktop { margin-bottom: 1.66667rem; } }

.ml-md { margin-left: 1.66667rem; }

@media (max-width: 767px) { .ml-md-phone { margin-left: 1.66667rem; } }

@media (min-width: 768px) and (max-width: 991px) { .ml-md-tablet { margin-left: 1.66667rem; } }

@media (min-width: 992px) { .ml-md-desktop { margin-left: 1.66667rem; } }

.mr-md { margin-right: 1.66667rem; }

@media (max-width: 767px) { .mr-md-phone { margin-right: 1.66667rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mr-md-tablet { margin-right: 1.66667rem; } }

@media (min-width: 992px) { .mr-md-desktop { margin-right: 1.66667rem; } }

.mx-md { margin-left: 1.66667rem; margin-right: 1.66667rem; }

@media (max-width: 767px) { .mx-md-phone { margin-left: 1.66667rem; margin-right: 1.66667rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mx-md-tablet { margin-left: 1.66667rem; margin-right: 1.66667rem; } }

@media (min-width: 992px) { .mx-md-desktop { margin-left: 1.66667rem; margin-right: 1.66667rem; } }

.my-md { margin-top: 1.66667rem; margin-bottom: 1.66667rem; }

@media (max-width: 767px) { .my-md-phone { margin-top: 1.66667rem; margin-bottom: 1.66667rem; } }

@media (min-width: 768px) and (max-width: 991px) { .my-md-tablet { margin-top: 1.66667rem; margin-bottom: 1.66667rem; } }

@media (min-width: 992px) { .my-md-desktop { margin-top: 1.66667rem; margin-bottom: 1.66667rem; } }

.mt-lg { margin-top: 2.77778rem; }

@media (max-width: 767px) { .mt-lg-phone { margin-top: 2.77778rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mt-lg-tablet { margin-top: 2.77778rem; } }

@media (min-width: 992px) { .mt-lg-desktop { margin-top: 2.77778rem; } }

.mb-lg { margin-bottom: 2.77778rem; }

@media (max-width: 767px) { .mb-lg-phone { margin-bottom: 2.77778rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mb-lg-tablet { margin-bottom: 2.77778rem; } }

@media (min-width: 992px) { .mb-lg-desktop { margin-bottom: 2.77778rem; } }

.ml-lg { margin-left: 2.77778rem; }

@media (max-width: 767px) { .ml-lg-phone { margin-left: 2.77778rem; } }

@media (min-width: 768px) and (max-width: 991px) { .ml-lg-tablet { margin-left: 2.77778rem; } }

@media (min-width: 992px) { .ml-lg-desktop { margin-left: 2.77778rem; } }

.mr-lg { margin-right: 2.77778rem; }

@media (max-width: 767px) { .mr-lg-phone { margin-right: 2.77778rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mr-lg-tablet { margin-right: 2.77778rem; } }

@media (min-width: 992px) { .mr-lg-desktop { margin-right: 2.77778rem; } }

.mx-lg { margin-left: 2.77778rem; margin-right: 2.77778rem; }

@media (max-width: 767px) { .mx-lg-phone { margin-left: 2.77778rem; margin-right: 2.77778rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mx-lg-tablet { margin-left: 2.77778rem; margin-right: 2.77778rem; } }

@media (min-width: 992px) { .mx-lg-desktop { margin-left: 2.77778rem; margin-right: 2.77778rem; } }

.my-lg { margin-top: 2.77778rem; margin-bottom: 2.77778rem; }

@media (max-width: 767px) { .my-lg-phone { margin-top: 2.77778rem; margin-bottom: 2.77778rem; } }

@media (min-width: 768px) and (max-width: 991px) { .my-lg-tablet { margin-top: 2.77778rem; margin-bottom: 2.77778rem; } }

@media (min-width: 992px) { .my-lg-desktop { margin-top: 2.77778rem; margin-bottom: 2.77778rem; } }

.mt-xl { margin-top: 8.33333rem; }

@media (max-width: 767px) { .mt-xl-phone { margin-top: 8.33333rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mt-xl-tablet { margin-top: 8.33333rem; } }

@media (min-width: 992px) { .mt-xl-desktop { margin-top: 8.33333rem; } }

.mb-xl { margin-bottom: 8.33333rem; }

@media (max-width: 767px) { .mb-xl-phone { margin-bottom: 8.33333rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mb-xl-tablet { margin-bottom: 8.33333rem; } }

@media (min-width: 992px) { .mb-xl-desktop { margin-bottom: 8.33333rem; } }

.ml-xl { margin-left: 8.33333rem; }

@media (max-width: 767px) { .ml-xl-phone { margin-left: 8.33333rem; } }

@media (min-width: 768px) and (max-width: 991px) { .ml-xl-tablet { margin-left: 8.33333rem; } }

@media (min-width: 992px) { .ml-xl-desktop { margin-left: 8.33333rem; } }

.mr-xl { margin-right: 8.33333rem; }

@media (max-width: 767px) { .mr-xl-phone { margin-right: 8.33333rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mr-xl-tablet { margin-right: 8.33333rem; } }

@media (min-width: 992px) { .mr-xl-desktop { margin-right: 8.33333rem; } }

.mx-xl { margin-left: 8.33333rem; margin-right: 8.33333rem; }

@media (max-width: 767px) { .mx-xl-phone { margin-left: 8.33333rem; margin-right: 8.33333rem; } }

@media (min-width: 768px) and (max-width: 991px) { .mx-xl-tablet { margin-left: 8.33333rem; margin-right: 8.33333rem; } }

@media (min-width: 992px) { .mx-xl-desktop { margin-left: 8.33333rem; margin-right: 8.33333rem; } }

.my-xl { margin-top: 8.33333rem; margin-bottom: 8.33333rem; }

@media (max-width: 767px) { .my-xl-phone { margin-top: 8.33333rem; margin-bottom: 8.33333rem; } }

@media (min-width: 768px) and (max-width: 991px) { .my-xl-tablet { margin-top: 8.33333rem; margin-bottom: 8.33333rem; } }

@media (min-width: 992px) { .my-xl-desktop { margin-top: 8.33333rem; margin-bottom: 8.33333rem; } }

.mt-none { margin-top: 0; }

@media (max-width: 767px) { .mt-none-phone { margin-top: 0; } }

@media (min-width: 768px) and (max-width: 991px) { .mt-none-tablet { margin-top: 0; } }

@media (min-width: 992px) { .mt-none-desktop { margin-top: 0; } }

.mb-none { margin-bottom: 0; }

@media (max-width: 767px) { .mb-none-phone { margin-bottom: 0; } }

@media (min-width: 768px) and (max-width: 991px) { .mb-none-tablet { margin-bottom: 0; } }

@media (min-width: 992px) { .mb-none-desktop { margin-bottom: 0; } }

.ml-none { margin-left: 0; }

@media (max-width: 767px) { .ml-none-phone { margin-left: 0; } }

@media (min-width: 768px) and (max-width: 991px) { .ml-none-tablet { margin-left: 0; } }

@media (min-width: 992px) { .ml-none-desktop { margin-left: 0; } }

.mr-none { margin-right: 0; }

@media (max-width: 767px) { .mr-none-phone { margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) { .mr-none-tablet { margin-right: 0; } }

@media (min-width: 992px) { .mr-none-desktop { margin-right: 0; } }

.mx-none { margin-left: 0; margin-right: 0; }

@media (max-width: 767px) { .mx-none-phone { margin-left: 0; margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) { .mx-none-tablet { margin-left: 0; margin-right: 0; } }

@media (min-width: 992px) { .mx-none-desktop { margin-left: 0; margin-right: 0; } }

.my-none { margin-top: 0; margin-bottom: 0; }

@media (max-width: 767px) { .my-none-phone { margin-top: 0; margin-bottom: 0; } }

@media (min-width: 768px) and (max-width: 991px) { .my-none-tablet { margin-top: 0; margin-bottom: 0; } }

@media (min-width: 992px) { .my-none-desktop { margin-top: 0; margin-bottom: 0; } }

/**********
 * Blocks *
***********/
.header { position: fixed; height: 50px; padding-top: 30px; left: 0; right: 0; background-color: #000000; z-index: 99; }

@media (max-width: 767px) { .header { padding-top: 15px; height: 30px; } }

.header__logo { display: block; max-width: 140px; border-bottom: none; }

.header__logo[href] { opacity: 0.7; transition-property: opacity; }

.header__logo[href]:hover { opacity: 1; }

.header__lang { line-height: 1; text-align: right; text-transform: uppercase; }

.short-footer { margin-top: 150px; padding-bottom: 30px; color: rgba(255, 255, 255, 0.5); }

@media (max-width: 767px) { .short-footer { margin-top: 50px; padding-bottom: 15px; } }

.socials { margin-top: 20px; }

.socials__item { position: relative; margin-top: 30px; background-color: #000000; }

.socials__item::before { position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: 0 4px 200px rgba(255, 255, 255, 0.5); z-index: -1; content: ""; }

.socials__item::after { display: block; padding-top: 100%; content: ""; }

.socials__item svg { position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; }

.socials__item--twitter svg { width: 40%; }

.socials__item--facebook svg { width: 20%; }

.socials__item--reddit svg { width: 40%; }

.socials__item--youtube svg { width: 40%; }

.socials__item--vk svg { width: 40%; }

.meeting { position: relative; height: 100vh; }

.meeting__img { position: absolute; width: 100%; height: 100% !important; object-fit: cover; object-position: center; transform: scale(0.8); transition-property: opacity, transform; }

.meeting__img.loaded { transform: scale(1); }

.meeting__arrow { appearance: none; max-width: 100%; display: inline-block; text-decoration: none; border: none; vertical-align: middle; cursor: pointer; transition-property: border-color, background-color, color; transition-duration: 0.3s; outline: none !important; font-family: inherit; font-weight: 400; width: 50px; height: 24px; position: absolute; left: 0; right: 0; margin: auto; bottom: 30px; background: none; transition-property: transform; }

.meeting__arrow:hover { transition-duration: 0.1s; }

.meeting__arrow:disabled, .meeting__arrow[disabled], fieldset[disabled] .meeting__arrow, .meeting__arrow.disabled { pointer-events: none; }

.meeting__arrow--block { display: block; width: 100%; padding: 0; text-align: center; }

@media (max-width: 767px) { .meeting__arrow { bottom: 15px; } }

.meeting__arrow:hover, .meeting__arrow:focus { transform: scale(1.1); }

.meeting__arrow svg { height: 24px; }

.main-section { padding-top: 150px; }

@media (max-width: 767px) { .main-section { padding-top: 50px; } }

.game-card { position: relative; display: flex; border-bottom: none; background-color: #000000; line-height: 1.5; opacity: 0.7; transition-property: color, opacity; }

@media (max-width: 767px) { .game-card { flex-direction: column; } }

.game-card::before { position: absolute; left: 0; right: 0; top: 0; bottom: 0; z-index: -1; content: ""; box-shadow: 0 0 200px rgba(255, 255, 255, 0.75); }

.game-card + .game-card { margin-top: 2rem; }

.game-card:hover { opacity: 1; }

.game-card__body, .game-card__poster { flex: 0 0 50%; }

.game-card__body { display: flex; padding: 50px; }

@media (max-width: 1199px) { .game-card__body { padding: 30px; } }

@media (max-width: 767px) { .game-card__body { padding: 15px; } }

@media (max-width: 991px) { .game-card__body { flex-direction: column; } }

.game-card__logo { flex: 0 0 100px; max-width: 100px; }

.game-card__logo img { box-shadow: 0 0 150px rgba(255, 255, 255, 0.5); }

.game-card__info { flex-grow: 1; padding-left: 30px; }

@media (max-width: 991px) { .game-card__info { padding-left: 0; padding-top: 15px; } }

.game-card__title { display: block; margin: 0; color: #cccccc; }

.game-card:hover .game-card__title { color: #ffffff; }

.game-card__text { display: block; margin: 16px 0 0; }

.game-card__poster { position: relative; }

@media (max-width: 767px) { .game-card__poster { flex: 0 0 200px; } }

.game-card__poster img { position: absolute; left: 0; right: 0; top: 0; bottom: 0; height: 100% !important; object-fit: cover; object-position: center; }

@media (min-width: 768px) and (max-width: 991px) { .game-card__poster img { object-position: left; } }

.game-background { position: relative; height: 300px; }

.game-background__img { position: relative; width: 100%; height: 100% !important; object-fit: cover; object-position: center; }

.game-background__logo { position: absolute; width: 150px; margin: auto; left: 0; right: 0; bottom: 0; transform: translateY(50%); }

.game-info { margin-top: 150px; }

@media (max-width: 767px) { .game-info { margin-top: 100px; } }

@media (max-width: 991px) { .game-info__description { margin-bottom: 20px; } }

.game-info__description p:first-child { margin-top: 0; }

.game-info__store { position: relative; display: flex; height: 275px; justify-content: center; align-items: center; border-bottom: none; }

@media (max-width: 767px) { .game-info__store { height: 190px; } }

.game-info__store[href] { background-color: #000000; }

.game-info__store[href]::before { position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: 0 0 200px rgba(255, 255, 255, 0.4); transition: box-shadow 0.3s; content: ""; z-index: -1; }

.game-info__store[href]:hover::before { box-shadow: 0 0 200px rgba(255, 255, 255, 0.7); transition-duration: 0.1s; }

.game-info__store:not([href]) { border: 1px solid rgba(255, 255, 255, 0.25); color: rgba(255, 255, 255, 0.25); }

.game-info__store-logo { display: block; width: 200px; padding: 10px; max-width: 100%; text-align: center; }

.game-info__store-logo svg { width: 60px; }

.game-info__store-title { display: block; }

.game-slider-wrapper { overflow: hidden; }

.game-slider { margin-top: 70px; }

.game-slider__list { display: flex; flex-wrap: nowrap; }

.game-slider__slide { flex: 0 0 700px; }

@media (max-width: 991px) { .game-slider__slide { flex: 0 0 530px; } }

@media (max-width: 575px) { .game-slider__slide { flex: 0 0 320px; } }

.game-slider__slide:not(:first-child) { margin-left: 30px; }

@media (max-width: 767px) { .game-slider__slide:not(:first-child) { margin-left: 15px; } }

.content { margin-top: 150px; }

@media (max-width: 767px) { .content { margin-top: 70px; } }

/*********
 * Pages *
**********/
/************
 * External *
*************/
