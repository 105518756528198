img {
  max-width: 100%;
  height: auto;
}

img:only-child {
  vertical-align: bottom;
}

svg {
  fill: currentColor;
}

.lazy {
  display: block;
  opacity: 0;
  transition: opacity 0.3s;

  &.loaded {
    opacity: 1;
  }
}

.swiper-lazy {
  opacity: 0;
  transition: opacity 0.3s;

  &.swiper-lazy-loaded {
    opacity: 1;
  }
}

.img-circle {
  border-radius: 50%;
}

.img-smart {
  @each $breakpoint in ("md", "sm", "xs") {
    @include media-breakpoint-only($breakpoint) {
      $current-gutter: map_get($wrapper-gutters, $breakpoint);
      max-width: calc(100% + #{$current-gutter * 2});
      margin: 0 0 0 -#{$current-gutter};
    }
  }
}
