.form {
  max-width: 700px;
}

.form-group {
  margin: $form-group-margin 0 0;

  &__line {
    margin: $form-line-margin 0 0;
  }

  &--horizontal {
    display: flex;
    align-items: baseline;

    @include media-breakpoint-down(xs) {
      display: block;
    }
  }

  &--horizontal &__label {
    flex: 0 0 15ch;
    max-width: 15ch;
    margin-right: 5px;
    line-height: 1.25;

    @include media-breakpoint-down(xs) {
      display: block;
      flex: none;
      max-width: none;
      margin-right: 0;
      margin-bottom: 5px;
    }
  }

  &--horizontal &__field {
    flex-grow: 1;
    max-width: calc(100% - 15ch - 5px);

    @include media-breakpoint-down(xs) {
      flex: none;
      max-width: none;
    }
  }
}

.form-control {
  @include form-control();
  outline: none;

  @each $size in map_keys($form-control-sizes) {
    @if $size == default {
      @include form-control-size(map-deep-get($form-control-sizes, $size, "height"), map-deep-get($form-control-sizes, $size, "font-size"), $form-control-border-width, map-deep-get($form-control-sizes, $size, "field-radius"));
    } @else {
      &--#{$size} {
        @include form-control-size(map-deep-get($form-control-sizes, $size, "height"), map-deep-get($form-control-sizes, $size, "font-size"), $form-control-border-width, map-deep-get($form-control-sizes, $size, "field-radius"));
      }
    }
  }

  @each $color in map_keys($control-colors) {
    $border-color: map-deep-get($control-colors, $color, "border");
    @if $color == default {
      @include form-control-color(map-deep-get($control-colors, $color, "text"));
      @include form-control-border($border-color, $border-color, $border-color);
    } @else {
      &--#{$color} {
        @include form-control-color(map-deep-get($control-colors, $color, "text"));
        @include form-control-border($border-color, $border-color, $border-color);
      }
    }
  }

  &.error {
    @if $form-control-border-width != 0 {
      border-color: color(danger);
    } @else {
      background-color: rgba(color(danger), 0.15);
    }
  }
}

.form-error {
  @extend .text-small;
  margin-top: $form-line-margin;
  color: color(danger);
}
