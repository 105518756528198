@mixin form-control($font-family: inherit, $font-weight: 400) {
  $parent: &;

  appearance: none;
  display: block;
  max-width: 100%;
  background-image: none;
  background-clip: padding-box;
  line-height: $form-control-line-height;
  font-family: $font-family;
  font-weight: $font-weight;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;

  &:not([size]):not(select),
  &--full-size {
    width: 100%;
  }

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    transition-duration: 0.1s;
  }

  @at-root {
    textarea#{$parent} {
      resize: vertical;
    }

    select#{$parent}:not([size]):not([multiple]) {
      background-position: center right 10px;
      background-repeat: no-repeat;
    }
  }
}

@mixin form-control-size($input-height, $font-size: 1rem, $border-width: 1px, $border-radius: 0, $padding-horizontal: ($input-height * $form-control-horizontal-padding-multiplier)) {
  $parent: &;

  border-width: $border-width;
  border-style: solid;
  border-color: transparent;

  padding: calc((#{$input-height} - #{$font-size} * #{$form-control-line-height} - 2 * #{$border-width}) / 2) $padding-horizontal;
  border-radius: $border-radius;
  font-size: $font-size;

  @at-root {
    select#{$parent}:not([size]):not([multiple]) {
      height: $input-height;
      padding-right: calc(#{$padding-horizontal} + 10px);
    }
  }
}

@mixin form-control-color($color, $placeholder-color: rgba($color, 0.5), $background-color: #fff,
$background-color-focus: $background-color, $color-focus: $color,
$background-color-disabled: darken($background-color, 10), $color-disabled: $color) {
  $parent: &;

  color: $color;
  background-color: $background-color;

  &::placeholder {
    color: $placeholder-color;
    opacity: 1;
  }

  @at-root {
    select#{$parent}:not([size]):not([multiple]) {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='5' fill='#{$color}'><polygon points='0,0 10,0 5,5'/></svg>");
    }

    select#{$parent}:focus::-ms-value {
      color: $color;
      background-color: $background-color;
    }
  }

  &:focus {
    outline: none;
    color: $color-focus;
    background-color: $background-color-focus;
  }

  &:disabled,
  &[readonly] {
    background-color: $background-color-disabled;
    color: $color-disabled;
    opacity: 1;
  }
}

@mixin form-control-border($border-color,
$border-color-focus: darken($border-color, 25),
$border-color-disabled: $border-color) {
  $parent: &;

  border-color: $border-color;

  &:focus {
    border-color: $border-color-focus;
  }

  &:disabled,
  &[readonly] {
    border-color: $border-color-disabled;
  }

  @at-root {
    select#{$parent}:not([size]):not([multiple]) {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='5' fill='#{$border-color}'><polygon points='0,0 10,0 5,5'/></svg>");
    }

    select#{$parent}:not([size]):not([multiple]):focus {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='5' fill='#{$border-color-focus}'><polygon points='0,0 10,0 5,5'/></svg>");
    }
  }
}
