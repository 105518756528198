@each $color in $text-colors {
  .text-#{$color} {
    color: color($color);
  }
}

@each $color in $bg-colors {
  .bg-#{$color} {
    background-color: color($color);
  }
}
