@mixin google-map-height($height-desktop, $height-tablet, $height-mobile) {
  height: $height-desktop;

  @include media-breakpoint-down(md) {
    height: $height-tablet;
  }

  @include media-breakpoint-down(sm) {
    height: $height-mobile;
  }
}

.google-map {
  background-color: #dfdfdf;

  @each $height_type in map_keys($google-map-heights) {
    @if $height_type == default {
      @include google-map-height(map-deep-get($google-map-heights, $height_type, "desktop"), map-deep-get($google-map-heights, $height_type, "tablet"), map-deep-get($google-map-heights, $height_type, "mobile"));
    } @else {
      &--#{$height_type} {
        @include google-map-height(map-deep-get($google-map-heights, $height_type, "desktop"), map-deep-get($google-map-heights, $height_type, "tablet"), map-deep-get($google-map-heights, $height_type, "mobile"));
      }
    }
  }

  @include media-breakpoint-down(sm) {
    @each $breakpoint in ("sm", "xs") {
      @include media-breakpoint-only($breakpoint) {
        $current-gutter: map_get($wrapper-gutters, $breakpoint);
        margin: 0 -$current-gutter;
      }
    }
  }

  &__marker {
    display: none;
  }

  a {
    @extend .no-border;
  }
}
