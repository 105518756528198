.game-slider-wrapper {
  overflow: hidden;
}

.game-slider {
  margin-top: 70px;

  &__list {
    display: flex;
    flex-wrap: nowrap;
  }

  &__slide {
    flex: 0 0 700px;

    @include media-breakpoint-down(md) {
      flex: 0 0 530px;
    }

    @include media-breakpoint-down(xs) {
      flex: 0 0 320px;
    }
  }

  &__slide:not(:first-child) {
    margin-left: 30px;

    @include media-breakpoint-down(sm) {
      margin-left: 15px;
    }
  }
}