.game-info {
  margin-top: 150px;

  @include media-breakpoint-down(sm) {
    margin-top: 100px;
  }

  &__description {
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  &__description p:first-child {
    margin-top: 0;
  }

  &__store {
    position: relative;
    display: flex;
    height: 275px;
    justify-content: center;
    align-items: center;
    border-bottom: none;

    @include media-breakpoint-down(sm) {
      height: 190px;
    }
  }

  &__store[href] {
    background-color: color(black);
  }

  &__store[href]::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0 0 200px rgba(color(white), 0.4);
    transition: box-shadow 0.3s;
    content: "";
    z-index: -1;
  }

  &__store[href]:hover::before {
    box-shadow: 0 0 200px rgba(color(white), 0.7);
    transition-duration: 0.1s;
  }

  &__store:not([href]) {
    border: 1px solid rgba(color(white), 0.25);
    color: rgba(color(white), 0.25);
  }

  &__store-logo {
    display: block;
    width: 200px;
    padding: 10px;
    max-width: 100%;
    text-align: center;
  }

  &__store-logo svg {
    width: 60px;
  }

  &__store-title {
    display: block;
  }
}