.game-background {
  position: relative;
  height: 300px;

  &__img {
    position: relative;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
  }

  &__logo {
    position: absolute;
    width: 150px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(50%);
  }
}