@mixin button-active-classes($hover: false) {
  @if $hover {
    &:hover,
    &:active,
    &:focus,
    &.active {
      @content;
    }
  } @else {
    &:active,
    &:focus,
    &.active {
      @content;
    }
  }
}

@mixin button-disabled-classes() {
  &:disabled,
  &[disabled],
  fieldset[disabled] &,
  &.disabled {
    @content;
  }
}

@mixin button($font-family: inherit, $font-weight: 400) {
  appearance: none;
  max-width: 100%;
  display: inline-block;
  text-decoration: none;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  transition-property: border-color, background-color, color;
  transition-duration: 0.3s;
  outline: none !important;
  font-family: $font-family;
  font-weight: $font-weight;

  &:hover {
    transition-duration: 0.1s;
  }

  @include button-disabled-classes() {
    pointer-events: none;
  }

  &--block {
    display: block;
    width: 100%;
    padding: 0;
    text-align: center;
  }
}

@mixin button-size($height, $font-size: 1rem, $border-width: 0, $border-radius: 0, $padding-horizontal: ($height * $btn-horizontal-padding-multiplier)) {
  @if $border-width != 0 {
    padding: calc((#{$height} - #{$font-size} * #{$form-control-line-height} - #{2 * $border-width}) / 2) $padding-horizontal;
  } @else {
    padding: calc((#{$height} - #{$font-size} * #{$form-control-line-height}) / 2) $padding-horizontal;
  }

  border: $border-width solid transparent;
  border-radius: $border-radius;
  font-size: $font-size;
  line-height: $form-control-line-height;
}

@mixin button-color($color, $text-color,
$color-hover: darken($color, 10), $text-color-hover: $text-color,
$color-active: darken($color, 20), $text-color-active: $text-color,
$color-disabled: lighten($color, 35), $text-color-disabled: lighten($text-color, 35)) {
  background-color: $color;
  color: $text-color;

  &:hover {
    background-color: $color-hover;
    color: $text-color-hover;
  }

  @include button-active-classes() {
    background-color: $color-active;
    color: $text-color-active;
  }

  @include button-disabled-classes() {
    background-color: $color-disabled;
    color: $text-color-disabled;
  }
}

@mixin button-border-color($color, $color-hover: darken($color, 10),
$color-active: darken($color, 20), $color-disabled: lighten($color, 35)) {
  border-color: $color;

  &:hover {
    border-color: $color-hover;
  }

  @include button-active-classes() {
    border-color: $color-active;
  }

  @include button-disabled-classes() {
    border-color: $color-disabled;
  }
}
