html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: color(black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1 0 auto;
  max-width: 100%;
}

.text-block {
  max-width: 50rem;

  > *:first-child {
    margin-top: 0;
  }
}

.wrapper {
  max-width: $wrapper-max-width;
  margin: 0 auto;

  @each $breakpoint in map_keys($wrapper-gutters) {
    @include media-breakpoint-up($breakpoint) {
      $padding: map-get($wrapper-gutters, $breakpoint);

      @if $padding > 0 {
        padding: 0 $padding;
      }
    }
  }
}

.wrapper-half {
  box-sizing: content-box;
  max-width: ($wrapper-max-width / 2) - 15px;

  @each $breakpoint in map_keys($wrapper-gutters) {
    @include media-breakpoint-up($breakpoint) {
      $padding: map-get($wrapper-gutters, $breakpoint);

      @if $padding > 0 {
        padding-right: $padding;
      }
    }
  }

  @include media-breakpoint-down(md) {
    max-width: none;
  }
}

.unwrapper {
  @each $breakpoint in map_keys($wrapper-gutters) {
    @include media-breakpoint-up($breakpoint) {
      $padding: map-get($wrapper-gutters, $breakpoint);

      @if $padding > 0 {
        padding: 0 -$padding;
      }
    }
  }
}
