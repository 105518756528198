.radio {
  position: relative;
  display: block;
  font-size: 1rem;
  line-height: 1.25;
  min-height: 1em;
  padding-left: 1.5em;

  &__label::before,
  &__label::after {
    position: absolute;
    top: 0.125em;
    left: 0;
    width: 1em;
    height: 1em;
    content: "";
  }

  &__label::before {
    pointer-events: none;
    user-select: none;
    background-color: $checkbox-radio-bg;
    border-radius: 50%;
    transition: background-color 0.3s;
  }

  &__label::after {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 0.7em auto;
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__input:checked ~ &__label::before {
    background-color: $checkbox-radio-bg-active;
  }

  &__input:checked ~ &__label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$checkbox-radio-icon-color}'/%3E%3C/svg%3E");
  }

  &__input:not(:disabled):not(:checked) ~ &__label:hover::before {
    background-color: rgba($checkbox-radio-bg-active, 0.5);
    transition-duration: 0.1s;
  }

  &__input:disabled ~ &__label {
    color: color(light-gray);
  }

  &__input:disabled ~ &__label::before,
  &__input:disabled ~ &__label::after {
    opacity: 0.5;
  }
}
