.modal {
  z-index: 999;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $modal-overlay-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  &__container {
    position: relative;
    background-color: $modal-bg-color;
    color: $modal-text-color;
    overflow-y: auto;
    box-sizing: border-box;

    @each $breakpoint in map_keys($wrapper-gutters) {
      @include media-breakpoint-only($breakpoint) {
        $padding: map-get($wrapper-gutters, $breakpoint);

        width: calc(100% - #{2 * $padding});
        max-height: calc(100vh - #{2 * $padding});
      }
    }
  }

  @each $size in map_keys($modal-sizes) {
    &--#{$size} &__container {
      $width: map-deep-get($modal-sizes, $size, "max-width");
      $padding: map-deep-get($modal-sizes, $size, "padding");
      $border-radius: map-deep-get($modal-sizes, $size, "border-radius");

      max-width: $width;
      padding: $padding;
      border-radius: $border-radius;
    }
  }

  &--default &__container {
    @include media-breakpoint-down(sm) {
      padding: 30px;
    }
  }

  &--default &__container::after {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(55px);
    height: 5px;
    background-color: color(primary);
    background-image: linear-gradient(to right, color(secondary), color(primary));
    content: "";

    @include media-breakpoint-down(sm) {
      transform: translateY(25px);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__title {
    margin: 0 15px 0 0;
    color: $modal-text-color;
  }

  &__close {
    position: absolute;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding: 0;
    border: 3px solid color(white);
    outline: none;
    background-color: transparent;
    transition: border-color 0.3s, color 0.3s;
    color: color(white);
    cursor: pointer;
    right: 15px;
    top: 15px;
    z-index: 2;
  }

  &__close svg {
    width: 20px;
    height: 20px;
    pointer-events: none;
  }

  &__close:hover,
  &__close:focus {
    color: color(primary);
    border-color: color(primary);
    transition-duration: 0.1s;
  }

  &__close:active {
    border-color: color(secondary);
    color: color(secondary);
  }

  &__content {
    margin-top: 20px;
    margin-bottom: 2rem;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;

  &.is-open {
    display: block;
  }

  &[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[aria-hidden="false"] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[aria-hidden="true"] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  .modal__container,
  .modal__overlay {
    will-change: transform;
  }
}
