.meeting {
  position: relative;
  height: 100vh;

  &__img {
    position: absolute;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
    transform: scale(0.8);
    transition-property: opacity, transform;
  }

  &__img.loaded {
    transform: scale(1);
  }

  &__arrow {
    @include button();
    width: 50px;
    height: 24px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 30px;
    background: none;
    transition-property: transform;

    @include media-breakpoint-down(sm) {
      bottom: 15px;
    }

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }

  &__arrow svg {
    height: 24px;
  }
}