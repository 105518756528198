/***********************
* Настройки опасности задаются в этом документе
* Кроме этого должен быть создан селекторы "a" и "a u" со свойствами по-умолчанию
* (border-bottom-width, border-bottom-style, transition-ы и т.д.),
* а также класс .dashed для пунктирного подчеркивания
***********************/

$opacity: 0.3;
$opacity-dashed: 0.5;

@mixin link($color, $color-hover: $color, $color-focus: $color-hover, $border-color: $color,
$border-color-hover: $color-hover, $border-color-focus: $color-focus) {

  $opacity-hover: if($color-hover == $color, 1, $opacity);
  $opacity-dashed-hover: if($color-hover == $color, 1, $opacity-dashed);

  color: $color;
  border-bottom-color: rgba($border-color, $opacity);

  u {
    border-bottom-color: rgba($border-color, $opacity);
  }

  &:hover {
    color: $color-hover;
    border-bottom-color: rgba($border-color-hover, $opacity-hover);
  }

  &:hover u {
    border-bottom-color: rgba($border-color-hover, $opacity-hover);
  }

  &.dashed,
  &.dashed u {
    border-bottom-color: rgba($border-color, $opacity-dashed);
  }

  &.dashed:hover,
  &.dashed:hover u {
    border-bottom-color: rgba($border-color-hover, $opacity-dashed-hover);
  }
}
