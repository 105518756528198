.header {
  position: fixed;
  height: 50px;
  padding-top: 30px;
  left: 0;
  right: 0;
  background-color: color(black);
  z-index: 99;

  @include media-breakpoint-down(sm) {
    padding-top: 15px;
    height: 30px;
  }

  &__logo {
    display: block;
    max-width: 140px;
    border-bottom: none;
  }

  &__logo[href] {
    opacity: 0.7;
    transition-property: opacity;
  }

  &__logo[href]:hover {
    opacity: 1;
  }

  &__lang {
    line-height: 1;
    text-align: right;
    text-transform: uppercase;
  }
}
