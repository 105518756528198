.game-card {
  position: relative;
  display: flex;
  border-bottom: none;
  background-color: color(black);
  line-height: 1.5;
  opacity: 0.7;
  transition-property: color, opacity;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    content: "";
    box-shadow: 0 0 200px rgba(color(white), 0.75);
  }

  & + & {
    margin-top: 2rem;
  }

  &:hover {
    opacity: 1;
  }

  &__body,
  &__poster {
    flex: 0 0 50%;
  }

  &__body {
    display: flex;
    padding: 50px;

    @include media-breakpoint-down(lg) {
      padding: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding: 15px;
    }
  }

  &__body {
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__logo {
    flex: 0 0 100px;
    max-width: 100px;
  }

  &__logo img {
    box-shadow: 0 0 150px rgba(color(white), 0.5);
  }

  &__info {
    flex-grow: 1;
    padding-left: 30px;

    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-top: 15px;
    }
  }

  &__title {
    display: block;
    margin: 0;
    color: color(link);
  }

  &:hover &__title {
    color: color(link-hover);
  }

  &__text {
    display: block;
    margin: 16px 0 0;
  }

  &__poster {
    position: relative;

    @include media-breakpoint-down(sm) {
      flex: 0 0 200px;
    }
  }

  &__poster img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100% !important;
    object-fit: cover;
    object-position: center;

    @include media-breakpoint-only(md) {
      object-position: left;
    }
  }
}