.card {
  max-width: 360px;
  font-size: (16px / 18px) + rem;

  @include media-breakpoint-down(sm) {
    font-size: (16px / 16px) + rem;
  }

  &__link {
    @extend .no-border;
  }

  &__image {
    display: block;
  }

  &__image img {
    vertical-align: bottom;
  }

  &__name,
  &__price {
    font-weight: map_get($font-weights, semi-bold);
    font-size: (20px / 18px) + rem;

    @include media-breakpoint-down(sm) {
      font-size: (20px / 16px) + rem;
    }
  }

  &__name {
    display: block;
    margin-top: 15px;
  }

  &__name b {
    font-weight: map_get($font-weights, black);
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  &__price {
    margin-top: 5px;
    white-space: nowrap;
    color: color(black);
  }
}