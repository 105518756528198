.socials {
  margin-top: 20px;

  &__item {
    position: relative;
    margin-top: 30px;
    background-color: color(black);
  }

  &__item::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0 4px 200px rgba(color(white), 0.5);
    z-index: -1;
    content: "";
  }

  &__item::after {
    display: block;
    padding-top: 100%;
    content: "";
  }

  &__item svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &__item--twitter svg {
    width: 40%;
  }

  &__item--facebook svg {
    width: 20%;
  }

  &__item--reddit svg {
    width: 40%;
  }

  &__item--youtube svg {
    width: 40%;
  }

  &__item--vk svg {
    width: 40%;
  }
}