.table-responsive,
table:not([class]) {
  margin: 0.5rem 0 0.75rem;
  border-spacing: 0;
  border-collapse: collapse;

  td:not([valign]),
  th:not([valign]) {
    vertical-align: top;
  }

  th:not([align]) {
    text-align: left;
  }

  th {
    padding: 0.5rem 1.5rem;
    font-weight: map_get($table-styles, heading-weight);
    border-bottom: 1px solid map_get($table-styles, heading-border-color);
  }

  tbody td {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid map_get($table-styles, border-color);
    transition: background-color 0.3s;
  }

  tbody tr:hover td {
    @include media-breakpoint-up(lg) {
      background-color: map_get($table-styles, hover-color);
      transition-duration: 0.1s;
    }
  }
}

table:not([class]) {
  display: inline-block;
  overflow-x: auto;
  max-width: 100%;
  vertical-align: top;
  background: linear-gradient(rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%) 0 0,
    linear-gradient(rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%) 100% 0;
  background-attachment: scroll, scroll;
  background-size: 1px 100%, 1px 100%;
  background-repeat: no-repeat, no-repeat;

  tbody tr:last-child td {
    border-bottom: none;
  }

  td:first-child,
  th:first-child {
    background-image: linear-gradient(to right, white 50%, rgba(255, 255, 255, 0) 100%);
    background-size: 2px 100%;
    background-repeat: no-repeat;
  }

  td:last-child,
  th:last-child {
    background-image: linear-gradient(to left, white 50%, rgba(255, 255, 255, 0) 100%);
    background-position: 100% 0;
    background-size: 2px 100%;
    background-repeat: no-repeat;
  }

  td:only-child,
  th:only-child {
    background-image: linear-gradient(to right, white 50%, rgba(255, 255, 255, 0) 100%),
      linear-gradient(to left, white 50%, rgba(255, 255, 255, 0) 100%);
    background-position: 0 0, 100% 0;
    background-size: 2px 100%, 2px 100%;
    background-repeat: no-repeat, no-repeat;
  }
}

.table-responsive {
  @include media-breakpoint-down(md) {
    display: block;
  }

  tr,
  tbody {
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  thead {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  tr {
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }

  tbody td,
  thead th {
    @include media-breakpoint-down(md) {
      display: flex;
      padding-left: 0;
      padding-right: 0;
    }

    @include media-breakpoint-down(xs) {
      display: block;
    }
  }

  tbody td::before {
    @include media-breakpoint-down(md) {
      content: attr(data-caption);
      flex: 0 0 30%;
      padding-right: 10px;
      font-weight: bold;
    }

    @include media-breakpoint-down(xs) {
      display: block;
      flex: none;
      padding-right: 0;
    }
  }

  tbody tr:last-child td {
    @include media-breakpoint-up(lg) {
      border-bottom: none;
    }
  }

  tbody td:last-child {
    @include media-breakpoint-down(md) {
      border-bottom: none;
    }
  }
}
