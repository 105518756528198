.btn {
  @include button();
  @include button-size(50px, (20px / 18px) + rem, 2px, 0);
  @include button-color(transparent, color(black), transparent, color(primary), transparent, color(secondary));
  @include button-border-color(color(black), color(primary), color(secondary));
  font-weight: map_get($font-weights, semi-bold);

  &--white {
    @include button-color(transparent, color(white), transparent, color(primary), transparent, color(secondary));
    @include button-border-color(color(white), color(primary), color(secondary));
  }
}

.circle-btn {
  @include button();
  display: flex;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none;
  color: color(black);
  border-radius: 50%;
  background-color: color(white);

  &:hover {
    color: color(primary);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &--arrow svg {
    transform: translateX(2px);
  }
}
