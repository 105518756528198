@mixin add-sizes-breakpoints() {
  &-phone {
    @include media-breakpoint-down(sm) {
      @content;
    }
  }

  &-tablet {
    @include media-breakpoint-only(md) {
      @content;
    }
  }

  &-desktop {
    @include media-breakpoint-up(lg) {
      @content;
    }
  }
}

@each $size in map_keys($margins) {
  $value: map_get($margins, $size);

  .mt-#{$size} {
    margin-top: $value;
    @include add-sizes-breakpoints(){
      margin-top: $value;
    }
  }

  .mb-#{$size} {
    margin-bottom: $value;
    @include add-sizes-breakpoints(){
      margin-bottom: $value;
    }
  }

  .ml-#{$size} {
    margin-left: $value;
    @include add-sizes-breakpoints(){
      margin-left: $value;
    }
  }

  .mr-#{$size} {
    margin-right: $value;
    @include add-sizes-breakpoints(){
      margin-right: $value;
    }
  }

  .mx-#{$size} {
    margin-left: $value;
    margin-right: $value;
    @include add-sizes-breakpoints(){
      margin-left: $value;
      margin-right: $value;
    }
  }

  .my-#{$size} {
    margin-top: $value;
    margin-bottom: $value;
    @include add-sizes-breakpoints(){
      margin-top: $value;
      margin-bottom: $value;
    }
  }
}

.mt-none {
  margin-top: 0;
  @include add-sizes-breakpoints(){
    margin-top: 0;
  }
}

.mb-none {
  margin-bottom: 0;
  @include add-sizes-breakpoints(){
    margin-bottom: 0;
  }
}

.ml-none {
  margin-left: 0;
  @include add-sizes-breakpoints(){
    margin-left: 0;
  }
}

.mr-none {
  margin-right: 0;
  @include add-sizes-breakpoints(){
    margin-right: 0;
  }
}

.mx-none {
  margin-left: 0;
  margin-right: 0;
  @include add-sizes-breakpoints(){
    margin-left: 0;
    margin-right: 0;
  }
}

.my-none {
  margin-top: 0;
  margin-bottom: 0;
  @include add-sizes-breakpoints(){
    margin-top: 0;
    margin-bottom: 0;
  }
}
