a,
a u {
  text-decoration: none;
  border-color: inherit;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  transition-duration: 0.3s;
}

a {
  @include link(color(link), color(link-hover));
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  outline-color: inherit;
  transition-property: color, border-color;

  &:active,
  &:hover {
    outline-width: 0;
  }
}

a u {
  transition-property: border-color;
}

a[href^="tel"] {
  text-decoration: none;
  color: inherit;
  border: none;
}

a:hover,
a:hover u {
  transition-duration: 0.1s;
}

.dashed,
.dashed u {
  border-bottom-style: dashed;
}

.no-border {
  border-bottom: none;
}

@each $color in $link-colors {
  .link-#{$color} {
    @include link(color($color), color(link-hover));
  }
}
