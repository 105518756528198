.links-group {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  @each $size in map_keys($form-control-sizes) {
    @if $size == default {
      margin-left: -(map-deep-get($form-control-sizes, $size, "height") * $links-group-horizontal-padding-multiplier);
    } @else {
      &--#{$size} {
        margin-left: -(map-deep-get($form-control-sizes, $size, "height") * $links-group-horizontal-padding-multiplier);
      }
    }
  }

  &--center {
    justify-content: center;
  }

  &__item {
    max-width: 100%;
    border-bottom: none;
    line-height: $form-control-line-height;
    margin-top: $links-group-vertical-margin;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__item--pagination {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  @each $size in map_keys($form-control-sizes) {
    @if $size == default {
      &__item {
        padding: calc((#{map-deep-get($form-control-sizes, $size, "height")} - #{map-deep-get($form-control-sizes, $size, "font-size")} * #{$form-control-line-height}) / 2) (map-deep-get($form-control-sizes, $size, "height") * $links-group-horizontal-padding-multiplier);
        font-size: map-deep-get($form-control-sizes, $size, "font-size");
        border-radius: map-deep-get($form-control-sizes, $size, "button-radius");
      }

      &__item--pagination {
        width: map-deep-get($form-control-sizes, $size, "height");
      }
    } @else {
      &--#{$size} &__item {
        padding: calc((#{map-deep-get($form-control-sizes, $size, "height")} - #{map-deep-get($form-control-sizes, $size, "font-size")} * #{$form-control-line-height}) / 2) (map-deep-get($form-control-sizes, $size, "height") * $links-group-horizontal-padding-multiplier);
        font-size: map-deep-get($form-control-sizes, $size, "font-size");
        border-radius: map-deep-get($form-control-sizes, $size, "button-radius");
      }

      &--#{$size} &__item--pagination {
        width: map-deep-get($form-control-sizes, $size, "height");
      }
    }
  }

  &__item--active {
    background-color: color(primary);
    color: map-deep-get($btn-colors, "primary", "text");
    pointer-events: none;
  }

  &__item--active u {
    border-bottom: none;
  }

  &__item--disabled {
    color: color(gray);
    pointer-events: none;
  }

  &__item--disabled u {
    border-bottom: none;
  }
}
