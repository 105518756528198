.checkbox {
  position: relative;
  display: block;
  font-size: 1rem;
  line-height: 1.25;
  min-height: 1em;
  padding-left: 1.5em;

  &__label::before,
  &__label::after {
    position: absolute;
    top: 0.125em;
    left: 0;
    width: 1em;
    height: 1em;
    content: "";
  }

  &__label::before {
    pointer-events: none;
    user-select: none;
    background-color: $checkbox-radio-bg;
    border-radius: $checkbox-border-radius;
    transition: background-color 0.3s;
  }

  &__label::after {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 0.7em auto;
  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__input:checked ~ &__label::before {
    background-color: $checkbox-radio-bg-active;
  }

  &__input:checked ~ &__label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$checkbox-radio-icon-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }

  &__input:not(:disabled):not(:checked) ~ &__label:hover::before {
    background-color: rgba($checkbox-radio-bg-active, 0.5);
    transition-duration: 0.1s;
  }

  &__input:disabled ~ &__label {
    color: color(light-gray);
  }

  &__input:disabled ~ &__label::before,
  &__input:disabled ~ &__label::after {
    opacity: 0.5;
  }

  &__input.error ~ &__label::before {
    background-color: rgba(color(danger), 0.25);
  }
}
